import { EmailSignUpProps } from "@bluebottlecoffee/design-system";
import { toHTML } from "@portabletext/to-html";
import { handleEmailOptIn } from "../component-utils/email-opt-in";
import { EmailSignUp as EmailSignUpSchema } from "../sanity-schema";
import { toImageResponsiveProps } from "./image";

type EmailSignUpTransformerProps = {
  data: EmailSignUpSchema;
  lang: string;
};

const handleSubmit = (email: string) => {
  const trimmedEmail: string = email.trim();
  handleEmailOptIn(trimmedEmail);
};

export function toEmailSignUpProps({
  data,
  lang,
}: EmailSignUpTransformerProps): EmailSignUpProps {
  const { confirmationCopy, signUpCopy } = data;
  return {
    confirmationCopy: {
      confirmButton: confirmationCopy.confirmationButton[lang],
      ...(confirmationCopy.description && {
        description: toHTML(confirmationCopy.description[lang]),
      }),
      ...(confirmationCopy.heading && {
        heading: toHTML(confirmationCopy.heading[lang]),
      }),
    },
    image: toImageResponsiveProps(data.image, lang),
    ...(data.imageOpacity && { imageOpacity: data.imageOpacity }),
    signUpCopy: {
      ...(signUpCopy.description && {
        description: toHTML(signUpCopy.description[lang]),
      }),
      ...(signUpCopy.disclaimer && {
        disclaimer: toHTML(signUpCopy.disclaimer[lang]),
      }),
      ...(signUpCopy.heading && { heading: toHTML(signUpCopy.heading[lang]) }),
      inputPlaceholder: signUpCopy.inputPlaceholder[lang],
      submitButton: signUpCopy.submitButton[lang],
    },
    onSubmit: handleSubmit,
  };
}
