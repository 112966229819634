import { toHTML } from "@portabletext/to-html";
import type { BulletedKeyValue as BulletedKeyValueProps } from "@bluebottlecoffee/design-system/components/lib/types";
import { BulletedKeyValueListProps } from "@bluebottlecoffee/design-system";
import { toLinkProps } from "./link";
import { toImageProps } from "./image";
import { BulletedKeyValueList } from "../sanity-schema";

function validate(data: BulletedKeyValueList, lang: string) {
  if (!data?.bulletedKeyValues) {
    throw Error("No key/values provided");
  }

  data.bulletedKeyValues.forEach((kv) => {
    if (!kv.keyValue?.key[lang]) {
      throw Error("No key found for entry in list");
    }
  });
}

function toBulletedKeyValueProps(
  data: BulletedKeyValueList["bulletedKeyValues"][number],
  lang: string,
): BulletedKeyValueProps {
  return {
    ...(data.bullet && { image: toImageProps(data.bullet, lang) }),
    key: data.keyValue?.key[lang],
    value: toHTML(data.keyValue?.value?.[lang]),
  };
}

export function toBulletedKeyValueListProps(
  data: BulletedKeyValueList,
  bgColor: BulletedKeyValueListProps["bgColor"],
  region: string,
  lang: string,
): BulletedKeyValueListProps {
  validate(data, lang);
  return {
    ...(bgColor && { bgColor }),
    ...(data.cta?.text && { cta: toLinkProps(data.cta, region, lang) }),
    heading: toHTML(data.heading[lang]),
    subheading: toHTML(data.subheading?.[lang]),
    bulletedKeyValues: data.bulletedKeyValues.map((kv) =>
      toBulletedKeyValueProps(kv, lang),
    ),
  };
}
