import {
  format,
  Locale,
  nextFriday,
  nextMonday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
} from "date-fns";

/**
 * Converts the @param day from a number to long string format of the day of the week
 *
 * @param day The day of the week as a number (0-6 = Sun - Sat)
 * @return {string} The day of the week as a long string ("Monday", "Tuesday", etc)
 * */
export function getDayOfWeekLong(day: number, datefnsLocale: Locale): string {
  // Formats the result of the date created by the provided argument
  function createDayFormatFor(
    dayCreationCallback: (date: Date | number) => Date,
  ) {
    return format(dayCreationCallback(new Date()), "EEEE", {
      locale: datefnsLocale, // TODO datefnsLocale should be defined in ../lib/localize.ts
    });
  }
  switch (day) {
    case 1:
      return createDayFormatFor(nextMonday);
    case 2:
      return createDayFormatFor(nextTuesday);
    case 3:
      return createDayFormatFor(nextWednesday);
    case 4:
      return createDayFormatFor(nextThursday);
    case 5:
      return createDayFormatFor(nextFriday);
    case 6:
      return createDayFormatFor(nextSaturday);
    case 0:
    default:
      return createDayFormatFor(nextSunday);
  }
}
