import { CafeWithLink } from "@bluebottlecoffee/design-system";
import { bbcLogoFallBackImgSrc } from "../utils";
import { AlgoliaCafe as AlgoliaCafeSchema } from "../algolia/types";
import { Cafe as CafeSchema } from "../sanity-schema";
import { toImageProps } from "./image";
import { cafePage } from "../link-builders";
import { toHoursProps } from "./detail-page-overview";

type AlgoliaCafeTransformerProps = {
  data: AlgoliaCafeSchema | CafeSchema;
  lang: string;
  region: string;
};

export function toCafeCardProps({
  data,
  lang,
  region,
}: AlgoliaCafeTransformerProps): CafeWithLink {
  const { address, geoLocation } = data;
  return {
    name: data.name[lang],
    link: cafePage({ region, lang, slug: data.slug.current }),
    slug: data.slug.current,
    country: data.country,
    region: data.region[lang],
    geopoint: {
      lat: geoLocation.lat,
      lng: geoLocation.lng,
      alt: geoLocation.alt,
    },
    image: data.image
      ? toImageProps(data.image, lang)
      : { src: bbcLogoFallBackImgSrc, altText: "" },
    address: {
      street: address.street,
      extended: address.extended,
      locality: address.locality,
      district: address.district,
      postalCode: address.postalCode,
      countryCode: address.countryCode,
      default: true, // required in the type but not used here
    },
    hours: toHoursProps({ data: data.hours }) ?? [],
  };
}
