import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import {
  Configure,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-hooks-web";
import { Button, CafeLocatorProps } from "@bluebottlecoffee/design-system";
import { publicSearchClient } from "../clients";
import { CafeSearchHits } from "./CafeSearchHits";
import {
  cafesIndexSearchableAttribute,
  standardizeIndexName,
} from "../indices";

export type CafeSearchProps = {
  cafeLocatorProps: Omit<
    CafeLocatorProps,
    | "queryString"
    | "searchBox"
    | "totalResultsDisplay"
    | "viewMoreButton"
    | "onModuleSearch"
  >;
  isModule: boolean;
  lang: string;
  region: string;
  searchBoxIcon: JSX.Element;
  viewMoreText: string;
};

export const CafeSearch: FunctionComponent<CafeSearchProps> = ({
  cafeLocatorProps,
  isModule,
  lang,
  region,
  searchBoxIcon,
  viewMoreText,
}) => {
  const [queryString, setQueryString] = useState<string>("");
  const [hitsToDisplay, setHitsToDisplay] = useState<number>(9);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setHitsToDisplay(9);
    const query = e.target[0].value;
    setQueryString(query);
  };
  const handleViewMore = () => {
    const moreHits: number = hitsToDisplay + 9;
    setHitsToDisplay(moreHits);
  };

  const searchBox: JSX.Element = (
    <SearchBox
      onSubmit={handleSubmit}
      autoFocus
      classNames={{
        root: "search-box-root",
        input: "search-box-input",
        submit: "search-box-submit",
      }}
      placeholder={cafeLocatorProps.copy.searchInputPlaceholder}
      loadingIconComponent={() => null}
      resetIconComponent={() => null}
      searchAsYouType={false}
      submitIconComponent={() => searchBoxIcon}
    />
  );

  function goToSearchResults(moduleQuery: string): void {
    const query: string = encodeURI(
      `?${standardizeIndexName("cafes")}[query]=${moduleQuery}`,
    );
    const queryUrl: string = `/${region}/${lang}/cafes/search-results${query}`;
    window.open(`${queryUrl}`, "_self", "noreferrer");
  }

  /* Extract the queryString from the url on search-results page load */
  useEffect(() => {
    if (!isModule) {
      const decodedURI = decodeURI(window.location.search);
      const queryString = decodedURI.split("cafes[query]=")[1].split("&")[0];
      setQueryString(queryString);
    }
  }, []);

  return (
    <InstantSearch
      indexName={standardizeIndexName("cafes")}
      searchClient={publicSearchClient}
      routing
    >
      <CafeSearchHits
        cafeLocatorProps={{
          ...cafeLocatorProps,
          isModule,
          searchBox,
          searchQuery: queryString,
          totalResultsDisplay: hitsToDisplay,
          viewMoreButton: (
            <Button
              level="primary"
              text={viewMoreText}
              onClick={handleViewMore}
            />
          ),
          onModuleSearch: (moduleQuery: string) => {
            if (isModule) {
              goToSearchResults(moduleQuery);
            }
          },
        }}
        lang={lang}
        region={region}
      />
      <Configure
        distinct
        restrictSearchableAttributes={cafesIndexSearchableAttribute(lang)}
      />
    </InstantSearch>
  );
};
