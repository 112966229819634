import { CategoryCarouselProps } from "@bluebottlecoffee/design-system";
import { CategoryCarousel as Schema } from "../sanity-schema";
import { toCategoryCardProps } from "./category-card";

export function toCategoryCarouselProps(
  data: Schema,
  region: string,
  locale: string,
  isDark?: boolean,
): CategoryCarouselProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    categoryCards: data.categoryCards.map((c) =>
      toCategoryCardProps(c, region, locale, data.bgColor, isDark),
    ),
    isDark,
  };
}
