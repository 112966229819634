import { toHTML } from "@portabletext/to-html";
import { QuoteProps } from "@bluebottlecoffee/design-system";
import { Quote as Schema } from "../sanity-schema";

export function toQuoteProps(
  data: Schema,
  locale: string,
  topBorder?: boolean,
  isDark?: boolean,
): QuoteProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.hasIcon && { hasIcon: data.hasIcon }),
    ...(data.source && { source: toHTML(data.source[locale]) }),
    ...(topBorder && { topBorder }),
    author: toHTML(data.author[locale]),
    isDark,
    quote: toHTML(data.quote[locale]),
  };
}
