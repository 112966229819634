import { DetailPageOverviewProps } from "@bluebottlecoffee/design-system";
import { Hours } from "@bluebottlecoffee/design-system/components/lib/types";
import { AddressAndHoursProps } from "@bluebottlecoffee/design-system/components/modules/DetailPageOverview/AddressAndHour";
import { toHTML } from "@portabletext/to-html";
import { enUS } from "date-fns/locale";
import { getDayOfWeekLong } from "../component-utils/date-functions";
import {
  Cafe as CafeSchema,
  DetailPageOverview as DetailPageOverviewSchema,
} from "../sanity-schema";
import { toBulletedKeyValue } from "./bulleted-key-value";

type HoursTransformerProps = {
  data: CafeSchema["hours"];
  // datefnsLocale: Locale;
};

export function toHoursProps({
  data,
}: // datefnsLocale,
HoursTransformerProps): Hours[] | undefined {
  return data?.map((d) => ({
    day: getDayOfWeekLong(d.day, enUS), // TODO replace enUS with datefnsLocale variable when localization is set up
    open: d.open,
    close: d.close,
  }));
}

type AddressAndHoursTransformerProps = {
  data: CafeSchema;
};

export function toAddressAndHoursProps({
  data,
}: AddressAndHoursTransformerProps): Omit<AddressAndHoursProps, "copy"> {
  return {
    cafe: {
      address: {
        street: data.address.street,
        extended: data.address.extended,
        locality: data.address.locality,
        district: data.address.district,
        postalCode: data.address.postalCode,
        countryCode: data.address.countryCode,
        default: true,
      },
      hours: toHoursProps({ data: data.hours }) ?? [],
    },
    ...(data.cafeAddressLink && { cafeAddressLink: data.cafeAddressLink }),
  };
}

type CafeDetailPageOverviewTransformerProps = {
  data: DetailPageOverviewSchema;
  lang: string;
};

export function toCafeDetailPageOverviewProps({
  data,
  lang,
}: CafeDetailPageOverviewTransformerProps): Omit<
  DetailPageOverviewProps,
  "addressAndHours" | "keyValues"
> {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    detailOverviewCopyBlock: {
      heading: data.detailOverviewCopyBlock.heading[lang],
      description: data.detailOverviewCopyBlock.description
        ? toHTML(data.detailOverviewCopyBlock.description[lang])
        : undefined,
      isH1: true,
    },
  };
}

type BrewGuideDetailPageOverviewTransformerProps = {
  data: DetailPageOverviewSchema;
  region: string;
  lang: string;
};

export function toBrewGuideDetailPageOverviewProps({
  data,
  region,
  lang,
}: BrewGuideDetailPageOverviewTransformerProps): DetailPageOverviewProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    detailOverviewCopyBlock: {
      heading: data.detailOverviewCopyBlock.heading[lang],
      description: data.detailOverviewCopyBlock.description
        ? toHTML(data.detailOverviewCopyBlock.description[lang])
        : undefined,
      isH1: false,
    },
    keyValues: data.keyValues
      ? {
          keyValues: data.keyValues.map((kv) =>
            toBulletedKeyValue(kv, region, lang),
          ),
        }
      : { keyValues: [] },
  };
}
