import { FunctionComponent } from "react";
import { useHits } from "react-instantsearch-hooks-web";
import {
  CafeLocator,
  CafeLocatorProps,
  CafeWithLink,
} from "@bluebottlecoffee/design-system";
import { toCafeCardProps } from "../../transformers/cafe-card";
import { AlgoliaCafe } from "../types";

export type CafeSearchHitsProps = {
  cafeLocatorProps: Omit<CafeLocatorProps, "searchResults">;
  lang: string;
  region: string;
};

export const CafeSearchHits: FunctionComponent<CafeSearchHitsProps> = ({
  cafeLocatorProps,
  lang,
  region,
}) => {
  /*
   * useHits has to be called here before being wrapped by
   * InstantSearch in the CafeSearch component
   */
  const { hits } = useHits<AlgoliaCafe>();
  const cafeCards: CafeWithLink[] = hits.map((hit) =>
    toCafeCardProps({ data: hit, lang, region }),
  );

  return <CafeLocator {...cafeLocatorProps} searchResults={cafeCards} />;
};
