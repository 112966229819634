import {
  CategoryExploreProps,
  ImageLink,
} from "@bluebottlecoffee/design-system";
import {
  CategoryExplore as CategoryExploreSchema,
  ExploreLink as ExploreLinkSchema,
} from "../sanity-schema";
import { toImageResponsiveWithCaptionProps } from "./image";
import { toInternalUrl } from "./link";

type ImageLinkProps = {
  data: ExploreLinkSchema;
  region: string;
  lang: string;
};

type CategoryExploreTransformerProps = {
  data: CategoryExploreSchema;
  region: string;
  lang: string;
};

function toImageLink({ data, region, lang }: ImageLinkProps): ImageLink {
  return {
    image: toImageResponsiveWithCaptionProps(data.image, lang),
    url: toInternalUrl({ link: data.url, region, lang }),
  };
}

export function toCategoryExploreProps({
  data,
  region,
  lang,
}: CategoryExploreTransformerProps): CategoryExploreProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    heading: data.heading[lang],
    exploreLinks: data.exploreLinks.map((link) =>
      toImageLink({ data: link, region, lang }),
    ),
  };
}
