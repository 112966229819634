import { CafeLocatorProps, Icon } from "@bluebottlecoffee/design-system";
import { CafeSearchProps } from "../algolia/instant-search/CafeSearch";
import { Cafe, CafeLocator } from "../sanity-schema";
import { toCafeCardProps } from "./cafe-card";

type RenderedCafeLocatorProps = Omit<
  CafeLocatorProps,
  | "queryString"
  | "searchBox"
  | "totalResultsDisplay"
  | "viewMoreButton"
  | "onModuleSearch"
>;

export type DereferencedCafeLocator = Omit<CafeLocator, "previewCafes"> & {
  previewCafes: Cafe[];
};

type CafeLocatorTransformerProps = {
  data: DereferencedCafeLocator;
  lang: string;
  region: string;
};

export function toCafeLocatorProps({
  data,
  lang,
  region,
}: CafeLocatorTransformerProps): RenderedCafeLocatorProps {
  const { copy } = data;
  return {
    bgColor: data.bgColor ?? undefined,
    copy: {
      headline: copy.headline[lang],
      missingCafes: copy.missingCafes[lang],
      searching: copy.searching[lang],
      noCafesFound: copy.noCafesFound[lang],
      searchButton: copy.searchButton[lang],
      searchInputPlaceholder: copy.searchInputPlaceholder[lang],
      showingResults: copy.showingResults[lang],
    },
    previewCafes: data.previewCafes.length
      ? data.previewCafes?.map((cafe) =>
          toCafeCardProps({ data: cafe, lang, region }),
        )
      : [],
  };
}

type CafeSearchTransformerProps = CafeLocatorTransformerProps & {
  region: string;
};

export function toCafeSearchModuleProps({
  data,
  lang,
  region,
}: CafeSearchTransformerProps): CafeSearchProps {
  return {
    cafeLocatorProps: toCafeLocatorProps({ data, lang, region }),
    isModule: true,
    lang,
    region,
    searchBoxIcon: <Icon color="black" name="search" />,
    viewMoreText: data.copy.viewMoreText[lang],
  };
}
